@mixin alert-variant($background, $border, $color) {
  color: $color;
  //@include gradient-bg($background);
  border-color: $border;

  hr {
    border-top-color: $border;
  }

  .alert-link {
    color: $color;
  }
}
